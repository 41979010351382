.contain{
  width: 732px;
  height: 232px;
  display: flex;
  flex-wrap: wrap;
  padding: 36px 20px 36px 26px ;
  align-content: space-between;
  margin: -12px 0;
}

.containItem{
  width: 228px;
  height: 40px;
  padding: 6px 8px;
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  /* margin-bottom: 20px; */
}
.containItemNull{
  width: 228px;
  height: 40px;
}

.containItem:hover{
  background: rgba(246,248,251,.7);
}

.containItem:hover .arrowRight{
  background: rgba(246,248,251,.7);
  opacity: 1;
}
.containItem:hover span,.containItem:hover .icon{
  color: #6F40EE;
}

.containItem span{
  margin: 0;
}
.containItem .title{
  font-size: 14px;
  color: #1F2126;
  margin-left: 8px;
}

.containItem .icon{
  font-size: 28px;
  color: #696F7F;
}
.containItem .arrowRight{
  font-size: 14px;
  position: absolute;
  right: 12px;
  opacity: 0;
}